<template>
  <div class="container-browse">
    <div class="browse-header">
      <div class="header-top">
        <div class="header-top-left">
          <div class="col-md-3">
            <div class="thumb-container">
              <div class="thumb">
                <a href="javascript:;">
                  <img :src="auctionImage+imgSize(801)" alt="">
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="header-top-right">
          <div class="header-top-right-left">
            <div class="header-collot">
              <span class="header-weight">{{chineseTitleAuction}}</span>
              <div class="right-collot" @click="biddingCollot()" v-if="hide">
                <i class="iconfont " :class="[statusA ===0?'icon-kongaixin':'icon-aixin']"
                  style="color:rgb(234, 49, 74);font-size:13px"></i>
                <a href="javascript:;" class="click-collot-aa">收藏</a>
              </div>
              <div class="right-collot" @click="startBidding()" v-if="dataShowIf && hide">
                <i class="iconfont" :class="[remindStatus ===true?'icon-renwutixing':'icon-duanxintixing']" style="color: rgb(234, 49, 74);
                font-size: 15px;"></i>
                <a href="javascript:;" class="click-collot-aa">{{remindStatus===true?'取消提醒':'开拍提醒'}}</a>
              </div>
              <!-- <div class="right-collot" v-else style="border:1px solid #999">
                <i class="iconfont" :class="[remindStatus ===true?'icon-renwutixing':'icon-duanxintixing']" style="color: #999;
                font-size: 15px;"></i>
                <a href="javascript:;" class="click-collot-aa"
                  style="color:#999;">{{remindStatus===true?'取消提醒':'开拍提醒'}}</a>
              </div> -->
            </div>
            <div class="time-and-data" v-if="deferment === false">
              <span>{{auctionStartTime}}</span>
              <span class="left-right">|</span>
              <span>北京时间</span>
            </div>
            <a href="javascript:;"
              style="color:#333;font-size:0.104167rem;display:block;padding-bottom:0.104167rem;text-decoration:underline"
              v-if='deferment === true'>延期待定</a>
            <div class="auctionAddress">
              <img src="../../assets/images/V.png" alt="">
              <router-link target="_blank" class="auctionAddress-size size-a"
                :to="{path:'/auctionhousedetails',query:{auctionhousedetailsid:auctionHouseIdGo}}">
                {{auctionHouseName}}
              </router-link>
              <!-- <a href="javascript:;" class="auctionAddress-size size-a"
                @click="auctionHouseNames()">{{auctionHouseName}}</a> -->
            </div>
            <div class="auctionAddress">
              <img src="../../assets/images/didian.png" alt="">
              <span class="auctionAddress-size">{{auctionAddress}}</span>
            </div>
            <div class="auctionAddress">
              <img src="../../assets/images/bondY-fill.png" alt="">
              <span class="auctionAddress-size cda">保证金：{{auctionEarnestMoney}}</span>
            </div>
          </div>
          <div class="header-top-right-left-right">
            <!-- <div class="yz-z" v-if="auctionStartTime>dataIf">预展中</div>
            <div class="yz-z" v-else>已结束</div> -->
            <div class="yz-z" v-if="auctionStatus === 1 && dataShowIf">预约出价中</div>
            <!-- <a href="javascript:;" class="yz-z"
              style="background: #D10000;color: #fff;font-weight: 600;display:inline-block;"
              v-if="auctionStatus === 2 && accessTokenShow===false && auctionHouseLoginT === false"
              @click="buyerLive()">
              直播中</a> -->
            <router-link target='_blank'
              :to="{path:'/buyerlive',query:{auctionId:auctionInfoListData.id,auctionHouseTitle:auctionHouseName,title:chineseTitleAuction}}"
              class="yz-z" style="background: #D10000;color: #fff;font-weight: 600;display:inline-block;"
              v-if="auctionStatus === 2 && accessTokenShow===false">
              直播中</router-link>
            <router-link target='_blank'
              :to="{path:'/buyerlive',query:{auctionId:auctionInfoListData.id,auctionHouseTitle:auctionHouseName,title:chineseTitleAuction}}"
              class="yz-z" style="background: #D10000;color: #fff;font-weight: 600;display:inline-block;"
              v-if="auctionStatus === 2 && accessTokenShow && auctionHouseLoginNO">
              直播中</router-link>
            <router-link target='_blank'
              :to="{path:'/liveauctionhouse',query:{liveId:auctionInfoListData.id,title:chineseTitleAuction}}"
              class="yz-z" style="background: #D10000;color: #fff;font-weight: 600;display:inline-block;"
              v-if="auctionStatus === 2 && accessTokenShow && auctionHouseLoginN && whetherSelfAuctionHouse">
              直播中</router-link>
            <a href="javascript:;" class="yz-z"
              style="background: #D10000;color: #fff;font-weight: 600;display:inline-block;"
              v-if="auctionStatus === 2 && accessTokenShow && auctionHouseLoginN && whetherSelfAuctionHouse === false"
              @click="inoperable()">
              直播中</a>
            <div class="yz-z" v-if="auctionStatus === 3">拍卖顺延</div>
            <div class="yz-z" style="background: #DEDEDE;color: #999999;font-weight: 600;" v-if="auctionStatus === 4">
              已结束</div>

            <span class="cut-off" v-if="dataShowIf && auctionWay === 8 && deferment===false">{{endTime}}委托截止</span>
            <div class="first-top-time"
              v-if="dataShowIf && auctionStatus === 1 && auctionWayIdT === 7 && deferment===false">
              <i style="font-style:normal">直播倒计时:</i>
              <span class="red">{{time.D}}</span>
              <i>天</i>
              <span class="red">{{time.h}}</span>
              <i>时</i>
              <span class="red">{{time.m}}</span>
              <i>分</i>
              <span class="red">{{time.s}}</span>
              <i>秒</i>
            </div>
            <div class="first-top-time" v-if="dataShowIf &&auctionWayIdT === 8 && deferment===false">
              <span class="red">{{time.D}}</span>
              <i>天</i>
              <span class="red">{{time.h}}</span>
              <i>时</i>
              <span class="red">{{time.m}}</span>
              <i>分</i>
              <span class="red">{{time.s}}</span>
              <i>秒</i>
            </div>
            <span class="cut-off pd-off cut-off-1 " style="text-align:center;"
              v-if="Date.parse(endTime)<Date.parse(dataIf) && dataShowIf===false && auctionWayIdT === 8 && deferment===false">委托已结束</span>
          </div>
        </div>
      </div>
      <div class="auctionhome-header">
        <p>{{total}}个拍品</p>
        <div class="header-middle-5">
          <input type="text" placeholder="请输入拍品名称" v-model="auctionInfoListData.title" @keyup.enter='enterSearch'>
        </div>
        <div class="pagination">
          <div id="components-pagination-demo-mini">
            <a-pagination size="small" :total="total" @change="onChange" v-model="auctionInfoListData.page"
              :pageSize='auctionInfoListData.pageSize' />
          </div>
        </div>
        <div class="auctionhome-header-right">
          <span style="font-size:0.091146rem;">每页显示</span>
          <a-select placeholder="40" style="width: 120px" v-model="value2" @change="handleChange">
            <a-select-option :value="item.num" v-for="(item,index) in pageNum" :key="index">
              {{item.num}}
            </a-select-option>
          </a-select>
        </div>
      </div>
    </div>
    <div class="choose-browseall">
      <CategoryChoose v-on:emitData='emitChildData' />
      <Browseall :browseall='browseall' @userCollect='userCollect' :pageId='pageId' :page='auctionInfoListData.page '
        :pageSize='auctionInfoListData.pageSize' :chineseTitleAuctionStr='chineseTitleAuctionStr'
        :auctionWay='auctionWay' :auctionHouseName='auctionHouseName' :chineseTitleAuction='chineseTitleAuction' />
    </div>

    <div class="auctionhome-header-a">
      <p>{{total}}个拍品</p>
      <div class="pagination" @click="goTop()">
        <div id="components-pagination-demo-mini">
          <a-pagination size="small" :total="total" @change="onChange" v-model="auctionInfoListData.page"
            :pageSize='auctionInfoListData.pageSize' />
        </div>
      </div>
      <div class="auctionhome-header-right">
        <span style="font-size:0.091146rem;">每页显示</span>
        <a-select style="width: 120px" v-model="value2" @change="handleChange">
          <a-select-option :value="item.num" v-for="(item,index) in pageNum" :key="index">
            {{item.num}}
          </a-select-option>
        </a-select>
      </div>
    </div>
    <div class="productdetails-collapse">
      <a-collapse accordion>
        <a-collapse-panel key="2" header="焱十一服务规则">
          <p style="text-align:justify;">{{paymentTransport}}</p>
        </a-collapse-panel>
        <a-collapse-panel key="3" header="拍卖规则">
          <p style="text-align:justify;">{{auctionTerms}}</p>
        </a-collapse-panel>
      </a-collapse>
    </div>
    <login ref="login" />
  </div>
</template>
<script>
  import CategoryChoose from './components/CategoryChoose'
  import Browseall from './components/Browseall'
  import {
    auctionInfoList,
    collectAuction,
    emindShooting
  } from '../../api/index'
  import login from '../../components/login'
  export default {
    name: 'Browse',
    components: {
      Browseall,
      CategoryChoose,
      login
    },
    data() {
      return {
        value: '',
        isChoose: false,
        auctionInfoListData: {
          id: null,
          userId: Number(localStorage.getItem('accessId')) || 0,
          x_user_id: Number(localStorage.getItem('accessId')) || 0,
          authorization: localStorage.getItem('accessToken') || 0,
          auctionWayId: '',
          brandAuthorId: '',
          stylePeriodId: '',
          materialsCraftId: '',
          placeSourceId: '',
          auctionHouseId: '',
          time: '',
          page: 1,
          pageSize: 40,
          title: '',
          classifyId: '',
          twoClassifyId: ''
        },
        auctionName: '',
        remindStatus: null,
        auctionEarnestMoney: '',
        auctionStartTime: '',
        auctionHouseName: '',
        total: 0,
        auctionInfoListTitle: {},
        browseall: [],
        paymentTransport: '',
        auctionTerms: '',
        auctionAddress: '',
        auctionImage: '',
        auctionHouseIdGo: '',
        endTime: '',
        dataShowIf: null,
        pageNum: [{
            id: 1,
            num: 40
          },
          {
            id: 2,
            num: 80
          },
          {
            id: 3,
            num: 120
          }
        ],
        value2: 40,
        pageId: 1,
        statusA: null,
        top: 0,
        dataIf: '',

        time: {
          D: '',
          h: '',
          s: '',
          m: ''
        },
        isEnd: false,
        chineseTitleAuction: '',
        hide: true,
        auctionStatus: 0,
        auctionWay: 0,
        auctionWayIdT: 0,
        auctionStartTime: '',
        accessTokenShow: false,
        auctionHouseLoginN: false,
        auctionHouseLoginNO: false,
        auctionHouseLoginT: false,
        whetherSelfAuctionHouse: null,
        chineseTitleAuctionStr: '',
        deferment: null
      }
    },
    destroyed() {
      sessionStorage.removeItem('msgInfo1')
    },

    created() {
      if (sessionStorage.getItem('accessId')) {
        this.auctionInfoListData.userId = Number(sessionStorage.getItem('accessId'))
      }
      if (sessionStorage.getItem('msgInfo1')) {
        this.auctionInfoListData.page = Number(sessionStorage.getItem('currentPage1')) || 1
        this.auctionInfoListData.pageSize = Number(sessionStorage.getItem('select4')) || 40
        this.value2 = Number(sessionStorage.getItem('select4')) || 40
      } else {
        sessionStorage.removeItem('currentPage1')
        sessionStorage.removeItem('select4')

        this.auctionInfoListData.page = 1
        this.auctionInfoListData.pageSize = 40
        this.value2 = 40
      }
      sessionStorage.setItem('msgInfo', true)
      sessionStorage.setItem('calendTimeTrue', true)
      sessionStorage.setItem('overS', true)
      this.auctionInfoList()
      const that = this
      that.setEndTime()
      if (localStorage.getItem('auctionHouseLoginNum') === '1') {
        this.hide = false
      }
      if (localStorage.getItem('accessToken')) {
        this.accessTokenShow = true
      } else {
        this.accessTokenShow = false
      }
      if (localStorage.getItem('auctionHouseLoginNum')) {
        this.auctionHouseLoginT = true
        if (localStorage.getItem('auctionHouseLoginNum') === '1') {
          this.auctionHouseLoginN = true
        } else if (localStorage.getItem('auctionHouseLoginNum') === '2') {
          this.auctionHouseLoginNO = true
        }
      } else {
        this.auctionHouseLoginT = false
      }
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll, true)
    },
    watch: {
      auctionInfoListData: {
        handler(newVal, oldVal) {
          if (newVal.title === '') {

            // this.auctionInfoList()

          }
        },
        deep: true
      }
    },
    methods: {
      imgSize(sizeW, sizeh) {
        return `?x-oss-process=image/resize,m_pad,w_${sizeW},h_${sizeh||sizeW}`
      },
      inoperable() {
        this.$message.error('拍卖行账户不可以进行该操作，请切换至买家账户')
      },
      toggePay() {
        this.isChoose = !this.isChoose
      },
      emitChildData(data) {
        this.auctionInfoListData.auctionWayId = data.auctionWayId
        this.auctionInfoListData.brandAuthorId = data.brandAuthorId
        this.auctionInfoListData.stylePeriodId = data.stylePeriodId
        this.auctionInfoListData.materialsCraftId = data.materialsCraftId
        this.auctionInfoListData.placeSourceId = data.placeSourceId
        this.auctionInfoListData.auctionHouseId = data.auctionHouseId
        this.auctionInfoListData.classifyId = data.classifyId
        this.auctionInfoListData.twoClassifyId = data.twoClassifyId
        this.auctionInfoListData.page = data.page
        this.auctionInfoList()
      },
      handleScroll(e) {
        const scrolltop = e.target.scrollTop
        scrolltop > 30 ? (this.gotop = true) : (this.gotop = false)
      },
      goTop() {
        const timeTop = setInterval(() => {
          document.body.scrollTop = document.documentElement.scrollTop = this.top -= 50
          if (this.top <= 0) {
            clearInterval(timeTop)
          }
        }, 0)
      },
      // 获取页面滚动高度
      scrollHandle(e) {
        this.top = e.srcElement.scrollingElement.scrollTop // 获取页面滚动高度
      },
      // 开拍提醒
      async startBidding() {
        if (!localStorage.getItem('accessId')) {
          this.$nextTick(() => {
            this.$refs.login.open()
          })
          return
        }
        const res = await emindShooting({
          userId: Number(localStorage.getItem('accessId')),
          auctionId: Number(this.$route.query.auctiondetailsId),
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken')
        })
        if (res.code === 0) {
          this.$message.success(res.data.status?'系统将在拍卖会开始前1小时提醒您及时参与竞拍':'取消成功')
          this.auctionInfoList()
        } else {
          this.$message.error(res.msg)
        }
      },
      // 跳转拍卖行
      auctionHouseNames() {
        this.$router.push({
          path: '/auctionhousedetails',
          name: 'Auctionhousedetails',
          query: {
            auctionhousedetailsid: this.auctionHouseIdGo
          }
        })
      },
      // 收藏
      async biddingCollot(id) {
        if (!localStorage.getItem('accessId')) {
          this.$nextTick(() => {
            this.$refs.login.open()
          })
          return
        }
        const res = await collectAuction({
          userid: Number(localStorage.getItem('accessId')),
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken'),
          auctionId: Number(this.$route.query.auctiondetailsId)
        })
        if (res.code === 0) {
          if (res.data.status === false) {
            this.$message.error('取消收藏')
          } else {
            this.$message.success('收藏成功')
          }
          this.auctionInfoList()
        } else {
          this.$message.error(res.msg)
        }
      },
      // 时间转换
      times() {
        var date = new Date() // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-'
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
        var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
        var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
        var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
        var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
        this.dataIf = Y + M + D + ' ' + h + m + s // 时分秒可以根据自己的需求加上
      },
      // 拍卖会详情列表初始化
      async auctionInfoList() {
        // this.auctionInfoListTitle = JSON.parse(this.$route.query.auctiondetailsId)
        this.auctionInfoListData.id = Number(this.$route.query.auctiondetailsId)
        const res = await auctionInfoList(this.auctionInfoListData)
        if (res.code === 0) {
          this.browseall = res.data.list
          this.total = res.data.count
          this.statusA = res.data.status
          this.auctionWay = res.data.auctionWay
          this.auctionWayIdT = res.data.auctionWay
          this.auctionStatus = res.data.auctionStatus
          this.paymentTransport = res.data.paymentTransport
          this.auctionTerms = res.data.auctionTerms
          this.auctionHouseName = res.data.auctionHouseName
          this.auctionStartTime = res.data.auctionStartTime
          this.endTime = res.data.endTime
          this.endTime2 = res.data.endTime
          this.auctionAddress = res.data.auctionAddress
          this.auctionEarnestMoney = res.data.auctionEarnestMoney
          this.auctionName = res.data.auctionName
          this.remindStatus = res.data.remindStatus
          this.auctionImage = res.data.auctionImage
          this.auctionHouseIdGo = res.data.auctionHouseId
          this.pageId = this.auctionInfoListData.page
          this.chineseTitleAuction = res.data.chineseTitleAuction
          this.chineseTitleAuctionStr = res.data.chineseTitleAuction
          this.auctionStartTime = res.data.auctionStartTime
          this.whetherSelfAuctionHouse = res.data.whetherSelfAuctionHouse
          this.deferment = res.data.deferment
          this.times()
        } else if (res.msg === '此拍卖会已结束') {
          this.$message.error(res.msg)
          var time = setTimeout(() => {
            this.$router.push({
              path: '/auctionhome',
              name: 'Auctionhome'
            })
            clearInterval(time)
          }, 1500)
        }
      },
      userCollect() {
        this.auctionInfoList()
      },
      // 分页
      onChange(page) {
        this.auctionInfoListData.page = page
        this.auctionInfoList()
      },
      handleChange(value) {
        this.auctionInfoListData.page = 1
        this.auctionInfoListData.pageSize = value
        this.auctionInfoList()
      },
      setEndTime() {
        const that = this
        const interval = setInterval(function timestampToTime() {
          var date = new Date(that.auctionWay === 8 ? that.endTime.replace(/-/g, "/") : that.auctionStartTime
            .replace(/-/g, "/")) - new Date() // 计算剩余的毫秒数
          if (date < 0) {
            that.isEnd = true
            that.dataShowIf = false
            that.time = {
              D: '00',
              h: '00',
              s: '00',
              m: '00'
            }
            clearInterval(interval)
          } else {
            that.dataShowIf = true
            that.time.D = parseInt(date / 1000 / 60 / 60 / 24, 10) // 计算剩余的天
            if (that.time.D < 10) {
              that.time.D = '0' + that.time.D
            }
            that.time.h = parseInt((date / 1000 / 60 / 60) % 24, 10) // 计算剩余的小时
            if (that.time.h < 10) {
              that.time.h = '0' + that.time.h
            }
            that.time.m = parseInt((date / 1000 / 60) % 60, 10) // 计算剩余的分钟
            if (that.time.m < 10) {
              that.time.m = '0' + that.time.m
            }
            that.time.s = parseInt((date / 1000) % 60, 10) // 计算剩余的秒数
            if (that.time.s < 10) {
              that.time.s = '0' + that.time.s
            }
            return that.time.D + that.time.h + that.time.m + that.time.s
          }
        }, 1000)
      },
      // 跳转买家直播
      buyerLive() {
        if (!localStorage.getItem('accessToken')) {
          this.$nextTick(() => {
            this.$refs.login.open()
          })
        }
      },
      // 搜索
      enterSearch() {
        this.auctionInfoList()
      }
    }

  }
</script>
<style lang="less" scoped>
  .container-browse {

    // margin: 0 90px;
    // /deep/ .ant-pagination-item-active {
    //   border: none;
    // }
    #components-pagination-demo-mini .ant-pagination:not(:last-child) {
      margin-bottom: 24px;
    }

    /deep/ .ant-pagination-item-active a {
      color: #cda156;
    }

    /deep/ .ant-pagination-item a:hover {
      color: #cda156;
    }

    .el-select {
      width: 100px;
      height: 50px;
    }

    /deep/ .ant-pagination {
      font-size: 18px;
    }

    /deep/ .ant-collapse-header {
      text-align: left;
      padding: 30px 5px;
      font-size: 24px;
    }

    /deep/ .ant-pagination-item {
      border: 1px solid #ccc !important;
    }

    /deep/ .ant-collapse {
      background-color: #fff;
      border: none;
      position: relative;
    }

    /deep/ .ant-select {
      width: 80px !important;
    }

    /deep/ .ant-select-selection__placeholder {
      color: rgba(0, 0, 0, 0.65);
    }

    /deep/ .ant-pagination-item-active a {
      color: #fff;
      background: #cda156;
    }

    /deep/ .ant-pagination-item a:hover {
      color: #000;
    }

    /deep/ .ant-pagination-item {
      border: 1px solid #ccc !important;
      margin: 0px 5px;

      a {
        font-size: 15px;
      }
    }

    /deep/ .ant-pagination-prev {
      border: 1px solid #ccc !important;
      margin-right: 5px;
    }

    /deep/ .ant-pagination-next {
      border: 1px solid #ccc !important;
      margin-left: 5px;
    }

    /deep/ .anticon {
      vertical-align: 0;
    }

    /deep/ .ant-pagination-item-active {
      background: #cda156;
    }

    /deep/ .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
      left: 1690px;
      font-size: 24px;
    }

    .pagination {
      // margin-bottom: 60px;
      display: flex;

      #components-pagination-demo-mini {
        flex: 1;
        text-align: right;
        padding-right: 102px;
      }
    }

    .auctionhome-header {
      // height: 70px;
      // align-items: center;
      // display: flex;
      // justify-content: space-between;
      // border-bottom: 1px solid #dedede;
      // border-top: 1px solid #dedede;

      p:nth-child(1) {
        font-size: 24px;
        font-weight: 400;
        color: #333;
      }

      p {
        margin-bottom: 0;
      }

      .auctionhome-header-right {
        display: flex;
        align-items: center;

        span {
          font-size: 17.5px;
          color: #333;
          font-weight: 400;
          padding-right: 10px;
        }

        p:nth-child(2) {
          span {
            font-size: 20px;
            font-weight: 400;
            color: #333;
            padding-right: 10px;
          }

          a {
            color: #333;

            i {
              font-size: 20px;
            }
          }
        }
      }
    }

    .auctionhome-header-right {
      display: flex;
      align-items: center;

      span {
        font-size: 20px;
        color: #333;
        font-weight: 400;
        padding-right: 10px;
      }

      p:nth-child(2) {
        span {
          font-size: 20px;
          font-weight: 400;
          color: #333;
          padding-right: 10px;
        }

        a {
          color: #333;

          i {
            font-size: 20px;
          }
        }
      }
    }

    .productdetails-collapse {
      padding: 0 90px;

      p {
        text-align: left;
      }

      .productdetails-header-translation {
        display: flex;
        position: absolute;
        top: 28px;
        left: 115px;
        width: 70px;
        height: 25px;
        border: 1px solid #cda156;
        border-radius: 4px;
        align-items: center;
        justify-content: center;

        i {
          color: #cda156;
        }

        a {
          color: #cda156;
          padding-left: 5px;
          font-size: 14px;
          // margin-left: 40px;
        }
      }
    }

    .browse-header {
      margin: 30px 90px;
      border-bottom: 1px solid #dedede;

      .header-top {
        display: flex;
        width: 100%;

        .header-top-left {
          width: 330px;
          // height: 260px;
          margin-right: 65px;
          padding-bottom: 16px;

          // background-size: 80% !important;
          // background-position: center !important;
          // background-repeat: no-repeat !important;
          // opacity: 1;
          .col-md-3 {
            width: 100%;
            margin: 0;

            .thumb-container {
              width: 100%;
              // height: 405px;
              position: relative;
              padding-bottom: 100%;
              margin: 0;
              border: none;

              &:hover {
                box-shadow: none;
              }

              .thumb {
                position: absolute;
                width: 100%;
                height: 100%;
                text-align: center;
                margin: 0;
                border-top: none;
                border-bottom: none;
                border-left: none;

                &:hover {
                  box-shadow: none;
                }

                a {
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  margin: auto;
                  max-width: 100%;
                  max-height: 100%;

                  img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    max-width: 100%;
                    max-height: 100%;
                  }
                }
              }
            }
          }

          .auctionImage {
            height: 100%;
          }
        }

        .header-top-right {
          display: flex;
          justify-content: space-between;
          width: 100%;

          .header-top-right-left {
            display: flex;
            flex-direction: column;
            text-align: left;

            .header-collot {
              display: flex;
              align-items: center;
              margin-bottom: 10px;

              .right-collot {
                // width: 80px;
                height: 24px;
                border: 1px solid #cda156;
                opacity: 0.7;
                border-radius: 4px;
                background: #ffffff;
                margin: 0 0 0 20px;
                display: block;
                line-height: 21px;
                text-align: center;
                color: #cda156;
                padding: 0 9px;

                &:hover {
                  box-shadow: none;
                  text-decoration: none;
                }

                i {
                  font-size: 14px;
                }

                .click-collot-aa {
                  font-size: 14px;
                  color: #cda156;
                  padding-bottom: 0;
                  padding-left: 4px;

                  &:hover {
                    text-decoration: none;
                  }
                }
              }

              .right-collot-toggle {
                width: 80px;
                height: 24px;
                border: 1px solid #888887;
                opacity: 0.7;
                border-radius: 4px;
                background: #ffffff;
                margin: 0 0 0 20px;
                display: block;
                line-height: 21px;
                text-align: center;
                color: #cda156;

                &:hover {
                  box-shadow: none;
                  text-decoration: none;
                }

                i {
                  font-size: 14px;
                }

                .click-collot-aa {
                  font-size: 14px;
                  color: #888887;
                  padding-bottom: 0;
                  padding-left: 4px;

                  &:hover {
                    text-decoration: none;
                  }
                }
              }
            }

            .header-weight {
              color: #333;
              font-weight: 500;
              font-size: 36px;
              display: inline-block;
              max-width: 600px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              // padding-bottom: 20px;
            }

            .time-and-data {
              font-size: 20px;
              font-weight: 400;
              color: #333333;
              padding-bottom: 20px;

              .left-right {
                padding: 0 10px;
              }
            }

            .auctionAddress {
              padding-bottom: 20px;

              img {
                width: 20px;
                transform: translateY(-3px);
              }

              .auctionAddress-size {
                font-size: 20px;
                font-weight: 400;
                color: #333;
                padding-left: 11px;
              }

              .size-a {
                color: #2c3e50;

                &:hover {
                  text-decoration: underline;
                }
              }

              .cda {
                color: #cda156;
              }
            }
          }

          .header-top-right-left-right {
            height: 50px;
            border-radius: 8px;
            opacity: 0.7;
            display: flex;
            flex-direction: column;
            width: 350px;
            align-items: center;

            .first-top-time {
              font-size: 20px;

              i {
                padding: 0 5px;
                font-style: normal;
              }

              .red {
                color: #cd5656;
              }
            }

            .cut-off {
              color: #666;
              font-size: 20px;
              text-align: left;
              width: 300px;
              display: inline-block;
            }

            .cut-off-1 {
              color: #666;
              font-size: 20px;
              text-align: left;
              width: auto;
              display: inline-block;
            }

            .pd-off {
              padding-top: 4px;
            }

            .yz-z {
              font-size: 18px;
              font-weight: 600;
              line-height: 50px;
              color: #6e5121;
              margin-bottom: 20px;
              border-radius: 8px;
              width: 239px;
              background: #cda156;
            }

            .kp-tx {
              font-size: 18px;
              color: #cda156;
              font-weight: 400;
              display: block;
              margin-top: 20px;
            }
          }
        }
      }

      .header-middle {
        display: flex;
        justify-content: center;
        padding-bottom: 50px;
        border-bottom: 1px solid #dedede;

        span {
          font-size: 20px;
          font-weight: 400;
          color: #333;
        }
      }

      .auctionhome-header {
        height: 70px;
        align-items: center;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #dedede;
        border-top: 1px solid #dedede;

        p:nth-child(1) {
          font-size: 20px;
          font-weight: 400;
          color: #333;
        }

        p {
          margin-bottom: 0;
        }

        .header-middle-5 {
          input {
            width: 258px;
            height: 50px;
            border: 1px solid #bfc3c4;
            // border-top: 1px solid #999999;
            // border-bottom: 1px solid #999999;
            // border-left: 1px solid #999999;
            // border-right: none;
            border-radius: 8px;
            // border-bottom-left-radius: 8px;
            color: #999999;
            line-height: 42px;
            font-size: 16px;
            text-align: left;
            // outline: none;
            background-image: url("../../assets/images/sousuo@2x.png");
            background-repeat: no-repeat;
            background-size: 22px 22px;
            background-position: 216px;
            padding-left: 23px;
            font-size: 16px;
            outline-color: #cda156;
          }

          select {
            width: 100px;
            height: 100%;
            transform: translateY(-1px);
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
          }
        }

        .auctionhome-header-right {
          display: flex;
          align-items: center;

          p:nth-child(1) {
            span {
              font-size: 20px;
              color: #333;
              font-weight: 400;
              padding-right: 10px;
            }
          }

          p:nth-child(2) {
            span {
              font-size: 20px;
              font-weight: 400;
              color: #333;
              padding-right: 10px;
            }

            a {
              color: #333;

              i {
                font-size: 20px;
              }
            }
          }
        }
      }
    }

    .auctionhome-header-a {
      height: 100px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      border-bottom: 40px solid #f9f9f9;
      margin: 0 90px;

      p:nth-child(1) {
        font-size: 20px;
        font-weight: 400;
        color: #333;
      }

      p {
        margin-bottom: 0;
      }
    }

    .choose-browseall {
      display: flex;
    }
  }
</style>
