<template>
  <div class="container-choose">
    <div class="choose-left">
      <div class="choose-emty">
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">重置</el-checkbox>
      </div>
      <div class="choose-purchase" v-if="data !== null">
        <p>{{classifyIdName}}</p>
        <div class="checked">
          <!-- <el-checkbox-group v-model="checkedCitiesFive" @change="handleCheckedCitiesChange($event,classifyId.id)">
            <el-checkbox :label="item.id" v-for="(item,index) in classifyId.valueAttribute" :key="index"
              :checked='checkAlle'>
              {{item.name}} </el-checkbox>
          </el-checkbox-group> -->
          <el-tree :data="data" :render-content="renderContent" ref="tree" show-checkbox node-key="id"
            @check='handleNodeClick' :default-checked-keys='checkedCitiesFive' :props="defaultProps">
          </el-tree>
        </div>
      </div>
      <!-- <div class="choose-purchase" v-if="attributeList.valueAttribute !==null">
        <p>{{attributeList.name}}</p>
        <div class="checked">
          <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange($event,attributeList.id)">
            <el-checkbox :label="item.id" v-for="(item,index) in attributeList.valueAttribute" :key="index"
              :checked='checkAlle'>
              {{item.name}} </el-checkbox>
          </el-checkbox-group>
        </div>
      </div> -->
      <div class="choose-purchase" v-if="brandAuthorId.valueAttribute !==null">
        <p>{{brandAuthorId.name}}</p>
        <div class="checked">
          <el-checkbox-group v-model="checkedCitiesTwo" @change="handleCheckedCitiesChange($event,brandAuthorId.id)">
            <el-checkbox :label="item.id" v-for="(item,index) in brandAuthorId.valueAttribute" :key="index"
              :checked='checkAlle'>
              {{item.name}}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="choose-purchase" v-if="stylePeriodId.valueAttribute !==null">
        <p>{{stylePeriodId.name}}</p>
        <div class="checked">
          <el-checkbox-group v-model="checkedCitiesSthree" @change="handleCheckedCitiesChange($event,stylePeriodId.id)">
            <el-checkbox :label="item.id" v-for="(item,index) in stylePeriodId.valueAttribute" :key="index"
              :checked='checkAlle'>
              {{item.name}}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="choose-purchase" v-if="materialsCraftId.valueAttribute !==null">
        <p>{{materialsCraftId.name}}</p>
        <div class="checked">
          <el-checkbox-group v-model="checkedCitiesFour"
            @change="handleCheckedCitiesChange($event,materialsCraftId.id)">
            <el-checkbox :label="item.id" v-for="(item,index) in materialsCraftId.valueAttribute" :key="index"
              :checked='checkAlle'>
              {{item.name}}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>

      <!-- <div class="choose-purchase">
        <p>{{placeSourceId.name}}</p>
        <div class="checked">
          <el-checkbox-group v-model="checkedCitiesFive" @change="handleCheckedCitiesChange($event,placeSourceId.id)">
            <el-checkbox :label="item.id" v-for="(item,index) in placeSourceId.valueAttribute" :key="index"
              :checked='checkAlle'>
              {{item.name}} </el-checkbox>
          </el-checkbox-group>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import {
  // attribute,
  auctionInfoAttribute
} from '../../../api/index'
export default {
  name: 'Choose',
  components: {

  },
  inject: ['reload'],
  data () {
    return {
      attributeList: [],
      brandAuthorId: [],
      stylePeriodId: [],
      materialsCraftId: [],
      placeSourceId: [],
      auctionHouseId: [],
      classifyId: [],
      classifyIdName: '',
      defaultPropsI: [],
      twoClassifyId: [],
      checkAll: false,
      checkedCities: [],
      checkedCitiesTwo: [],
      checkedCitiesSthree: [],
      checkedCitiesFour: [],
      checkedCitiesFive: [],
      checkedCitiesSix: [],
      isIndeterminate: true,
      checkAlle: false,
      emitData: {
        auctionWayId: '',
        brandAuthorId: '',
        stylePeriodId: '',
        materialsCraftId: '',
        placeSourceId: '',
        auctionHouseId: '',
        classifyId: '',
        twoClassifyId: '',
        page: 1
      },
      data: [],
      defaultProps: {
        children: 'valueAttribute',
        label: 'name'
      }
    }
  },
  created () {
    this.attribute()
  },
  methods: {
    handleCheckAllChange (val) {
      this.checkedCities = []
      this.checkedCitiesTwo = []
      this.checkedCitiesSthree = []
      this.checkedCitiesFour = []
      this.checkedCitiesFive = []
      this.emitData.auctionWayId = ''
      this.emitData.brandAuthorId = ''
      this.emitData.stylePeriodId = ''
      this.emitData.materialsCraftId = ''
      this.emitData.placeSourceId = ''
      this.emitData.auctionHouseId = ''
      this.emitData.classifyId = ''
      this.emitData.twoClassifyId = ''
      this.$nextTick(() => {
        this.$refs.tree.setCheckedKeys([])
      })
      this.$emit('emitData', this.emitData)
    },
    handleCheckedCitiesChange (value, id) {
      if (id === 1) {
        this.emitData.auctionWayId = this.checkedCities.join(',')
      } else if (id === 2) {
        this.emitData.brandAuthorId = this.checkedCitiesTwo.join(',')
      } else if (id === 3) {
        this.emitData.stylePeriodId = this.checkedCitiesSthree.join(',')
      } else if (id === 4) {
        this.emitData.materialsCraftId = this.checkedCitiesFour.join(',')
      } else if (id === 5) {
        this.emitData.placeSourceId = this.checkedCitiesFive.join(',')
      } else if (id === 6) {
        this.emitData.auctionHouseId = this.checkedCitiesSix.join(',')
      } else if (id === 100) {
        this.emitData.classifyId = this.checkedCitiesFive.join(',')
      } else if (id === 101) {
        this.emitData.twoClassifyId = this.checkedCitiesSix.join(',')
      }
      this.$emit('emitData', this.emitData)
    },
    handleNodeClick (data) {
      this.emitData.classifyId = this.$refs.tree.getCheckedKeys().toString()
      this.$emit('emitData', this.emitData)
    },
    // 数据初始化列表
    async attribute () {
      const res = await auctionInfoAttribute({
        auctionId: '',
        id: Number(this.$route.query.auctiondetailsId),
        userId: Number(localStorage.getItem('accessId')) || 0,
        x_user_id: Number(localStorage.getItem('accessId')) || 0,
        authorization: localStorage.getItem('accessToken') || 0,
        auctionWayId: '',
        brandAuthorId: '',
        stylePeriodId: '',
        materialsCraftId: '',
        placeSourceId: '',
        auctionHouseId: '',
        time: '',
        page: 1,
        pageSize: 40,
        title: '',
        classifyId: '',
        twoClassifyId: ''
      })
      if (res.code === 0) {
        this.data = res.data.list[0].valueAttribute
        this.classifyIdName = res.data.list[0].name
        // this.attributeList = res.data.list[0]
        this.brandAuthorId = res.data.list[1] // 品牌作者
        this.stylePeriodId = res.data.list[2] // 风格时期
        this.materialsCraftId = res.data.list[3]
        // this.twoClassifyId = res.data.list[5]
        this.placeSourceId = res.data.list[4]
        // this.auctionHouseId = res.data.list[5]
      } else {
        this.$message.error(res.msg)
      }
    },
    // <span>({node.parent.data.count})</span>
    renderContent (h, {
      node,
      data,
      store
    }) {
      return (<
        span style = 'padding-bottom:5px;font-size:0.072917rem;color:#606266;font-weight:500' >
        <
          span > {
            node.label
          } &nbsp; < /span> < /
        span >
      )
    }

  }
}
</script>
<style lang="less" scoped>
  .container-choose {
    // width: 450px;
    display: flex;
    flex-direction: column;

    /deep/ .el-checkbox-group {
      display: flex;
      flex-direction: column;
    }

    /deep/ .el-checkbox__input.is-checked+.el-checkbox__label {
      color: #cda156;
    }

    /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #cda156;
      border-color: #cda156;
    }

    /deep/ .el-checkbox__inner {
      background-color: #fff;
      border-color: #dcdfe6;
    }

    /deep/ .el-checkbox {
      margin-bottom: 10px;
    }

    /deep/ .el-checkbox__inner:hover {
      border-color: #cda156;
    }

    /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
      background-color: #cda156 !important;
      border-color: #cda156 !important;
      content: "";
      position: absolute;
      display: block;
      height: 0.010417rem;
      transform: scale(0.5);
      left: 0;
      right: 0;
      top: 0.026042rem;
    }

    .choose-left {
      // width: 420px;
    }

    .choose-emty {
      width: 280px;
      height: 44px;
      border: 1px solid #dedede;
      line-height: 44px;
      margin: 20px 0 30px 90px;
      font-weight: 400;

      /deep/ .el-checkbox__inner {
        display: none;
      }

      /deep/ .el-checkbox__label {
        color: #cda156;
      }

      a {
        color: #6e5121;
        font-size: 16px;
      }
    }

    .choose-purchase {
      display: flex;
      flex-direction: column;
      // align-items: self-end;
      margin-left: 90px;
      text-align: left;
      border-bottom: 1px solid #d8d8d8;
      margin-bottom: 20px;

      p {
        margin-bottom: 10px;
      }

      p:nth-child(1) {
        font-weight: 600;
        font-size: 16px;
        color: #333333;
      }

      .checked {
        display: flex;
        flex-direction: column;
        // align-items: self-end;
        overflow: hidden;
        // height: 160px;
        max-height: 200px;
      }

      .checked:hover {
        white-space: nowrap; //阻止文本换行
        overflow: auto;
      }

      .choose-more {
        margin-top: 10px;
        width: 370px;
        text-align: left;
        // height: 42px;
        border-bottom: 1px solid #d8d8d8;
        margin-bottom: 20px;
        line-height: 42px;

        a {
          font-size: 14px;
          color: #cda156;
        }

        i {
          font-size: 14px;
          color: #cda156;
        }
      }
    }

    .choose-line {
      margin: 20px 0 20px 90px;
      width: 370px;
      border: 1px solid #d8d8d8;
    }
  }
</style>
