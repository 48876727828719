<template>
  <div class="container-editnicknames" v-if="visible">
    <!-- <div ref="refModel">
      <a-modal :getContainer="() => $refs.refModel" title="用户登录" :visible="visible" @cancel="handleCancel">
        <a-input placeholder="请输入手机号码" v-model="loginData.mobile" />

        <div class="code">
          <a-input placeholder="请输入验证码" v-model="loginData.code" />
          <button :disabled='btnBool' @click="userSendCode(1)">{{yzmtext}}</button>
        </div>
        <template slot="footer">
          <div class="ok">
            <a-button @click="handleLogin(2)">登录</a-button>
            <p class="top-login ">
              <el-checkbox v-model="checked"></el-checkbox>
              <span>我已阅读并同意</span>
              <a href="javascript:;" style="color:#cda156;" @click="termagreement()">《条款与协议》</a>
            </p>
          </div>
        </template>
      </a-modal>
    </div> -->
    <div class="ant-modal-mask" @click.stop="visibleFalse"></div>
    <div class="model">
      <div class="off-cha">
        <span class="iconfont icon-shanchu" @click="offModel"></span>
      </div>

      <div class="middle-login">
        <div class="table-chose">
          <a href="javascript:;" :class="{ active: activeShow === 1 }" @click="switching(1)">用户登录</a>
          <span> | </span>
          <a href="javascript:;" :class="{ active: activeShow === 2 }" @click="switching(2)">拍卖行登录</a>
          <!-- <span> | </span>
          <a href="javascript:;" :class="{ active: activeShow === 3 }" @click="switching(3)">商家登录</a> -->
        </div>
        <div class="user-login" v-if="activeShow === 1">
          <a-input placeholder="请输入手机号码" v-model="loginData.mobile" />
          <div class="code">
            <a-input placeholder="请输入验证码" v-model="loginData.code" />
            <button :disabled="btnBool" @click="userSendCode(1)">
              {{ yzmtext }}
            </button>
          </div>
          <div class="ok">
            <a-button @click="handleLogin(2)">登录</a-button>
            <p class="top-login ">
              <el-checkbox v-model="checked"></el-checkbox>
              <span>我已阅读并同意</span>
              <a href="javascript:;" style="color:#cda156;" @click="termagreement()">《条款与协议》</a>
            </p>
          </div>
        </div>
        <div class="auction-house" v-if="activeShow === 2">
          <div class="input-a-p">
            <input type="email" v-model="auctionData.userName" class="my-user" placeholder="请输入您的用户名或邮箱" />
            <input type="password" v-model="auctionData.password" class="pw-user" placeholder="请输入密码" />
            <a href="javascript:;" class="forget-pw" @click="forgetThePassword()">忘记密码</a>
          </div>
          <button class="login-auction" @click="auctionHouseLogin(1)">
            立即登录
          </button>
          <button class="auction-rz" @click="auctionHouseCheckin()">
            拍卖行入驻
          </button>
        </div>
        <div class="auction-house" v-if="activeShow === 3">
          <div class="input-a-p">
            <input v-model="auctionData.userName" class="my-user" placeholder="请输入您的用户名或手机号" />
            <input type="password" v-model="auctionData.password" class="pw-user" placeholder="请输入密码" />
            <a href="javascript:;" class="forget-pw" @click="forgetThePasswordShop()">忘记密码</a>
          </div>
          <button class="login-auction" @click="mallBusinessLogin()">
            立即登录
          </button>
          <button class="auction-rz" @click="gobusinessesIn()">
            商家入驻
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    userLogin,
    userSendCode,
    getToken,
    auctionHouseLogin,
    mallBusinessLogin
  } from '../api/index'
  export default {
    name: 'Editnicknames',
    inject: ['reload'],
    data() {
      return {
        visible: false,
        loginData: {
          mobile: '',
          code: '',
          type: '1'
        },
        yzmtext: '获取验证码',
        btnBool: false,
        checked: true,
        activeShow: 1,
        auctionData: {
          userName: '',
          password: ''
          // passwordAfter: ''
        }
      }
    },
    created() {},
    methods: {
      // 禁止输入空格
      keydown(e) {
        if (e.keyCode == 32) {
          e.returnValue = false
        }
      },
      // 登录
      async handleLogin(e) {
        if (this.checked === false) {
          this.$message.error('请勾选协议')
          return
        }
        if (this.loginData.mobile === '') {
          this.$message.error('请输入手机号')
          return
        }
        if (
          !/^((1[3,5,6,8,7,9][0-9])|(14[5,7])|(17[0,6,7,8])|(19[1,7]))\d{8}$/.test(
            this.loginData.mobile
          )
        ) {
          this.$message.error('请输入正确手机号')
          return
        }
        if (this.loginData.code === '') {
          this.$message.error('请输入验证码')
          return
        }
        if (this.loginData.code.length !== 4) {
          this.$message.error('验证码位数不正确')
          return
        }
        const res = await userLogin(this.loginData)
        if (res.code === 0) {
          localStorage.setItem('auctionHouseLoginNum', e)
          if (res.data.oldUser === true) {
            this.$store.commit('setToken', res.data.accessToken)
            this.$store.commit('setUserId', res.data.id)
            window.localStorage.setItem('accessId', res.data.id)
            localStorage.setItem('accessToken', res.data.accessToken)
            this.getToken(res.data.id)
            this.visible = false
            this.loginData.mobile = ''
            this.loginData.code = ''
            var timer = setTimeout(() => {
              location.reload()
              clearInterval(timer)
            }, 500)
            this.$message.success('登录成功~')
          } else {
            this.$store.commit('setToken', res.data.accessToken)
            this.$store.commit('setUserId', res.data.id)
            window.localStorage.setItem('accessId', res.data.id)
            localStorage.setItem('accessToken', res.data.accessToken)
            this.getToken(res.data.id)
            this.visible = false
            this.loginData.mobile = ''
            this.loginData.code = ''
            var timers = setTimeout(() => {
              location.reload()
              clearInterval(timers)
            }, 500)
            this.$message.success('检查您的账号未注册，已为您注册账号并进行登录~')
          }
        } else {
          this.$message.error(res.msg)
        }
      },
      // 获取token
      async getToken(userid) {
        const res = await getToken({
          userid: userid
        })
      },
      handleCancel(e) {
        this.visible = false
      },
      open() {
        this.visible = true
        this.activeShow = 1
        this.auctionData.userName = ''
        this.auctionData.password = ''
        // this.auctionData.passwordAfter = ''
      },
      // 点击获取验证码封装
      onCode() {
        this.btnBool = true
        let i = 60
        var timer = setInterval(() => {
          this.yzmtext = '' + i + 's'
          i--
          if (i < 0) {
            this.btnBool = false
            this.yzmtext = '获取验证码'
            clearInterval(timer)
          }
        }, 1000)
      },
      // 点击获取验证码
      async userSendCode(value) {
        if (this.loginData.mobile === '') {
          this.$message.error('请输入手机号')
          return
        }
        if (
          !/^((1[3,5,6,8,7,9][0-9])|(14[5,7])|(17[0,6,7,8])|(19[1,7]))\d{8}$/.test(
            this.loginData.mobile
          )
        ) {
          this.$message.error('请输入正确手机号')
          return
        }
        const params = {
          mobile: this.loginData.mobile
        }
        this.btnBool = true
        const res = await userSendCode(params)
        if (res.code === 0) {
          this.$message.success('发送成功')
        } else {
          this.$message.error('发送失败')
        }
        this.onCode()
      },
      termagreement() {
        this.visible = false
        this.$router.push({
          path: '/termagreement',
          name: 'Termagreement'
        })
      },
      visibleFalse() {
        this.visible = false
      },
      switching(index) {
        this.activeShow = index
      },
      offModel() {
        this.visible = false
      },
      //商家登录
      async mallBusinessLogin() {
        if (
          this.auctionData.userName === '' ||
          this.auctionData.password === ''
        ) {
          this.$message.error('请输入相关信息')
          return
        }
        let newObj = JSON.parse(JSON.stringify(this.auctionData))
        newObj.password = this.$md5(newObj.password)
        const res = await mallBusinessLogin({
          account: newObj.userName,
          account_pwd: newObj.password
        })
        if (res.flag === true) {
          this.visible = false
          localStorage.setItem('auctionHouseLoginNum', 3)
          localStorage.setItem('accessToken', res.accessToken)
          localStorage.setItem('enterprise_id', res.data.enterprise_id)
          localStorage.setItem('user_name', res.data.user_name)
          localStorage.setItem('user_phone', res.data.user_phone)
          var timer = setTimeout(() => {
            location.reload()
            clearInterval(timer)
          }, 500)
          this.$message.success('登录成功')
          this.$router.push({
            path: '/mallBusinessCenter',
            name: 'mallBusinessCenter'
          })
        } else {
          this.$message.error(res.msg)
        }
      },
      // 拍卖行登录
      async auctionHouseLogin(index) {
        if (
          this.auctionData.userName === '' ||
          this.auctionData.password === ''
        ) {
          this.$message.error('请输入相关信息')
          return
        }
        let newObj = JSON.parse(JSON.stringify(this.auctionData))
        newObj.password = this.$md5(newObj.password)
        const res = await auctionHouseLogin(newObj) // this.auctionData
        if (res.code === 0) {
          this.visible = false
          localStorage.setItem('auctionHouseLoginNum', index)
          localStorage.setItem('accessToken', res.data.accessToken)
          localStorage.setItem('accessId', res.data.id)
          var timer = setTimeout(() => {
            location.reload()
            clearInterval(timer)
          }, 500)
          this.$message.success('登录成功')
          this.$router.push({
            path: '/auctionpersonalcenter',
            name: 'Auctionpersonalcenter'
          })
        } else if (res.data !== null) {
          if (res.data.accessToken !== '') {
            this.visible = false
            // this.$message.error(res.msg)
            localStorage.setItem('statusLogon', res.data.status)
            var timerA = setTimeout(() => {
              if (this.$route.name == 'Auctionhousecheckin') {
                location.reload()
              } else {
                this.$router.push({
                  path: '/auctionhousecheckin',
                  name: 'Auctionhousecheckin'
                })
              }
              clearInterval(timerA)
            }, 500)
            this.loginData.mobile = ''
            this.loginData.code = ''
            localStorage.setItem('loginId', res.data.id)
            localStorage.setItem('loginToken', res.data.accessToken)
          }
        } else if (res.data === null) {
          this.$message.error(res.msg)
        } else {
          var timerT = setTimeout(() => {
            location.reload()
            clearInterval(timerT)
          }, 500)
          this.$message.error(res.msg)
          this.loginData.mobile = ''
          this.loginData.code = ''
        }
      },
      // 忘记密码
      forgetThePassword() {
        this.$router.push({
          path: '/forgetthepassword',
          name: 'ForgetThePassword'
        })
        this.visible = false
      },
      // 忘记密码-商家
      forgetThePasswordShop() {
        this.$router.push({
          path: '/forgetthepasswordShop',
          name: 'ForgetThePasswordShop'
        })
        this.visible = false
      },
      //商家入驻
      gobusinessesIn() {
        this.visible = false
        this.$router.push({
          path: '/businessesIn',
          name: 'businessesIn'
        })
        if (this.$route.path === '/businessesIn') {
          this.time = setTimeout(() => {
            localStorage.clear()
            location.reload()
          }, 500)
        }
      },
      // 拍卖行入驻
      auctionHouseCheckin() {
        this.visible = false
        this.$router.push({
          path: '/auctionhousecheckin',
          name: 'Auctionhousecheckin'
        })
        if (this.$route.path === '/auctionhousecheckin') {
          this.time = setTimeout(() => {
            localStorage.clear()
            location.reload()
          }, 500)
        }
      }
    }
  }
</script>
<style lang="less" scoped>
  .container-editnicknames {

    // width: 100%;
    // height: 100%;
    // background: rgba(0, 0, 0, 0.2);
    .ant-modal-mask {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1000;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.45);
    }

    /deep/ .ant-modal-header {
      text-align: center;
      border: none;
    }

    /deep/ .ant-modal-footer {
      text-align: center;
      border: none;
    }

    /deep/ .ant-input:focus {
      border-color: #cda156;
    }

    /deep/ .el-checkbox__inner {
      border-color: #b3b0b0;
    }

    /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #cda156;
    }

    .code {
      margin-top: 30px;
      display: flex;
      align-items: center;

      /deep/ .ant-input {
        width: 200px;
        height: 40px;
        // margin-left: 115px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      button {
        width: 120px;
        height: 40px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        outline: none;
        color: #fff;
        background: #cda156;
        border: none;
      }

      /deep/ .ant-input-group-addon {
        background-color: #fff;
      }

      /deep/ .ant-btn {
        color: #fff;
        font-weight: 400;
        font-size: 16px;

        background: #cda156;
        border: none;
      }

      /deep/ .ant-btn {
        width: 120px;
        margin-right: 116px;
      }
    }

    /deep/ .ant-input {
      width: 320px;
      height: 40px;
    }

    /deep/ .ant-input:hover {
      border-color: #cda156;
    }

    /deep/ .ant-input:focus {
      box-shadow: none;
    }

    .ok {
      /deep/ .ant-btn {
        width: 140px;
        height: 50px;
        color: #6e5121;
        font-weight: 600;
        font-size: 20px;
        background: #f5edde;
        border: none;
        margin-top: 20px;
      }

      .top-login {
        margin-top: 10px;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          padding-left: 5px;
        }
      }
    }

    /deep/ .ant-modal-content {
      width: 600px;
      height: 393px;
    }

    /deep/ .ant-modal-title {
      font-size: 22px;
      margin-top: 30px;
    }

    /deep/ .ant-modal-body {
      margin-top: 20px;
    }

    .model {
      width: 600px;
      height: max-content;
      background: #fff;
      border-radius: 8px;
      position: fixed;
      margin: auto;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1000;

      .off-cha {
        text-align: right;
        margin-right: 20px;
        margin-top: 20px;
      }

      .middle-login {
        padding: 0 140px 0 140px;

        .table-chose {
          padding-bottom: 39px;
          display: flex;
          align-items: center;
          justify-content: center;

          a {
            font-size: 20px;
            font-weight: 400;
            color: #666;
          }

          span {
            padding: 8px;
            font-size: 20px;
          }

          .active {
            font-weight: 600;
          }
        }

        .auction-house {
          .input-a-p {
            display: flex;
            flex-direction: column;

            input {
              width: 320px;
              height: 50px;
              background: #fff;
              border: 1px solid #dedede;
              margin-bottom: 10px;
              font-size: 16px;
              color: #999;
              outline: none;
              padding: 14px;
              padding-left: 41px;
            }

            .my-user {
              background: url('../assets/images/zhanghaodenglu@2x.png') no-repeat;
              background-size: 16px;
              background-position: 13px;
            }

            .pw-user {
              background: url('../assets/images/mima-2@2x.png') no-repeat;
              background-size: 16px;
              background-position: 13px;
            }

            .forget-pw {
              color: #cda156;
              font-weight: 400;
              font-size: 12px;
              text-align: right;
              text-decoration: underline;
            }
          }

          .login-auction {
            width: 320px;
            color: #6e5121;
            font-weight: 600;
            font-size: 18px;
            border-radius: 8px;
            background: #f5edde;
            height: 44px;
            text-align: center;
            line-height: 44px;
            border: none;
            margin-top: 20px;
            margin-bottom: 10px;
          }

          .auction-rz {
            width: 320px;
            color: #cda156;
            font-weight: 500;
            font-size: 18px;
            border: 1px solid #cda156;
            border-radius: 8px;
            height: 44px;
            line-height: 44px;
            text-align: center;
            background: #fff;
            margin-bottom: 40px;
          }
        }
      }
    }
  }
</style>
