<template>
  <div class="container-recommendedIteright">
    <div class="recommended-item-img" v-for="(item, index) in browseall" :key="index" v-show="browseall !== null">
      <div class="recommended-item-img-top">
        <div class="love" @click="tabImgColor(item.id, item.status)" :class="{ active: item.status }" v-if="hide"></div>
        <!-- <div :style="{background:'url('+item.hostImage+')' } " class="forBackgroundImage"
          @click="productDetails(item.id)">
        </div> -->
        <div class="col-md-3">
          <div class="thumb-container">
            <div class="thumb">
              <router-link target="_blank" :to="{
                  path: '/productdetails',
                  query: { productdetailsId: item.id }
                }">
                <!-- <a href="javascript:;" @click="productDetails(item.id)"> -->
                <img :src="
                    item.hostImage +
                      imgSize(990)
                  " alt="" />
                <!-- </a> -->
              </router-link>
            </div>
          </div>
        </div>
        <div class="status-item" v-if="item.itemAuctionStatus === 3 && item.itemAuctionWayId === 7"
          style="background: #CDA156;">
          成交
        </div>
        <div class="status-item" v-if="item.itemAuctionStatus === 4 && item.itemAuctionWayId === 7"
          style="background: #BFC3C4;color: #666666;">
          流拍
        </div>
        <div class="status-item" v-if="item.itemAuctionStatus === 5 && item.itemAuctionWayId === 7"
          style="background: #BFC3C4;color: #666666;">
          撤拍
        </div>

        <router-link target="_blank" :to="{
            path: '/buyerlive',
            query: {
              auctionId: $route.query.auctiondetailsId,
              auctionHouseTitle: auctionHouseName,
              title: chineseTitleAuctionStr
            }
          }" class="status-item" v-if="
            item.itemAuctionStatus === 2 &&
              item.itemAuctionWayId === 7 &&
              auctionHouseLoginT === false
          ">
          直播中</router-link>

        <router-link target="_blank" :to="{
            path: '/buyerlive',
            query: {
              auctionId: $route.query.auctiondetailsId,
              auctionHouseTitle: auctionHouseName,
              title: chineseTitleAuctionStr
            }
          }" class="status-item" v-if="
            item.itemAuctionStatus === 2 &&
              item.itemAuctionWayId === 7 &&
              accessShow === false &&
              auctionHouseLoginNO
          ">
          直播中</router-link>
        <router-link target="_blank" :to="{
            path: '/liveauctionhouse',
            query: {
              liveId: $route.query.auctiondetailsId,
              title: chineseTitleAuction
            }
          }" class="status-item" v-if="
            item.itemAuctionStatus === 2 &&
              item.itemAuctionWayId === 7 &&
              accessShow === false &&
              auctionHouseLoginN &&
              item.whetherSelfAuctionHouse
          ">
          直播中</router-link>
        <a href="javascript:;" class="status-item" v-if="
            item.itemAuctionStatus === 2 &&
              item.itemAuctionWayId === 7 &&
              accessShow === false &&
              auctionHouseLoginN &&
              item.whetherSelfAuctionHouse === false
          ">直播中</a>
        <div class="status-item" style="background: #CDA156;"
          v-if="item.itemAuctionStatus === 1 && item.itemAuctionWayId === 7">
          待拍
        </div>
      </div>
      <div class="recommended-item-img-bottom" :title="item.title">
        <router-link target="_blank" :to="{
            path: '/productdetails',
            query: { productdetailsId: item.id }
          }">
          <!-- <a @click="productDetails(item.id)"> -->
          Lot&nbsp;{{ item.lot }}：{{ item.title }}
          <!-- </a> -->
        </router-link>
        <p class="font-size-small">
          拍品估价：{{ item.assess }}&nbsp;{{ item.currency }}
        </p>
        <p class="start-pirce" v-if="item.nowPrice === '' && item.itemAuctionStatus !== 3">
          起拍价格：{{ item.initialPrice }}&nbsp;{{ item.currency }}
        </p>
        <p class="start-pirce" v-else-if="item.nowPrice !== '' && item.itemAuctionStatus !== 3">
          当前价格：{{ item.nowPrice }}&nbsp;{{ item.currency }}
        </p>
        <p class="start-pirce" v-else-if="
            item.nowPrice !== '' &&
              item.itemAuctionStatus === 3 &&
              accessShow === false
          ">
          落槌价：{{ item.nowPrice }}&nbsp;{{ item.currency }}
        </p>
        <p class="start-pirce" v-else-if="
            item.nowPrice === '' &&
              item.itemAuctionStatus === 3 &&
              accessShow === false
          ">
          落槌价：{{ item.nowPrice }}&nbsp;{{ item.currency }}
        </p>
        <p class="start-pirce" v-else-if="accessShow && item.itemAuctionStatus === 3">
          落槌价：请登录
        </p>
      </div>
    </div>
    <div v-if="browseall === null" class="emty-brow">
      <img src="../../../assets/images/22.png" alt="" />
      <span>此分类没有任何产品</span>
    </div>
    <Login ref="login" />
  </div>
</template>
<script>
  import Login from '../../../components/login.vue'
  import {
    userCollect
  } from '../../../api/index'
  export default {
    name: 'recommendedIteright',
    components: {
      Login
    },
    props: {
      browseall: {
        type: Array,
        default: () => {}
      },
      pageId: {
        type: Number
      },
      page: {
        type: Number
      },
      pageSize: {
        type: Number
      },
      auctionWay: {
        type: Number
      },
      auctionHouseName: {
        type: String
      },
      chineseTitleAuctionStr: {
        type: String
      },
      chineseTitleAuction: {
        type: String
      }
    },
    data() {
      return {
        browseallProps: [],
        hide: true,
        accessShow: true,
        auctionHouseLoginN: false,
        auctionHouseLoginNO: false,
        auctionHouseLoginT: false
      }
    },
    created() {
      if (localStorage.getItem('auctionHouseLoginNum') === '1') {
        this.hide = false
      }
      if (!localStorage.getItem('accessToken')) {
        this.accessShow = true
      } else {
        this.accessShow = false
      }
      if (localStorage.getItem('auctionHouseLoginNum')) {
        this.auctionHouseLoginT = true
        if (localStorage.getItem('auctionHouseLoginNum') === '1') {
          this.auctionHouseLoginN = true
        } else if (localStorage.getItem('auctionHouseLoginNum') === '2') {
          this.auctionHouseLoginNO = true
        }
      } else {
        this.auctionHouseLoginT = false
      }
    },
    mounted() {},

    methods: {
      imgSize(sizeW, sizeh) {
        return `?x-oss-process=image/resize,m_pad,w_${sizeW},h_${sizeh||sizeW}`
      },
      tabImgColor(id, status) {
        if (!localStorage.getItem('accessToken')) {
          this.accessShow = true
          this.$nextTick(() => {
            this.$refs.login.open()
          })
        } else {
          this.accessShow = false
          if (status === true) {
            this.$message.error('取消收藏')
          } else {
            this.$message.success('收藏成功')
          }
          this.userCollect(id)
        }
      },
      // 点击收藏
      async userCollect(id) {
        const res = await userCollect({
          userid: Number(localStorage.getItem('accessId')),
          itemid: id,
          x_user_id: Number(localStorage.getItem('accessId')) || 0,
          authorization: localStorage.getItem('accessToken')
        })
        if (res.code === 0) {
          // this.$message.success('收藏成功~')
          this.$emit('userCollect')
        } else {
          this.$message.error(res.msg)
        }
      },
      // 跳转产品详情
      productDetails(id) {
        sessionStorage.setItem('currentPage1', this.page)
        sessionStorage.setItem('select4', this.pageSize)
        this.$router.push({
          path: '/productdetails',
          name: 'Productdetails',
          query: {
            productdetailsId: id,
            page: this.pageId
          }
        })
      },
      // 跳转买家直播
      buyerLive() {
        if (!localStorage.getItem('accessToken')) {
          this.$nextTick(() => {
            this.$refs.login.open()
          })
        }
      }
    }
  }
</script>
<style lang="less" scoped>
  .container-recommendedIteright {
    // display: flex;
    width: 1728px;
    // height: 540px;
    display: flex;
    flex-wrap: wrap;

    .recommended-item-img:hover {
      box-shadow: 2px 2px 20px 2px rgba(46, 46, 46, 0.1);
    }

    .recommended-item-img {
      width: 330px;
      height: 490px;
      margin: 19px;
      box-shadow: 0px 1px 16px 0px rgba(46, 46, 46, 0.05);
      background: #fff;

      .recommended-item-img-top {
        width: 100%;
        height: 330px;
        margin: 0;
        position: relative;
        overflow: hidden;

        .status-item {
          width: 80px;
          height: 34px;
          line-height: 34px;
          text-align: center;
          font-size: 18px;
          font-weight: 600;
          background: #d10000;
          color: #fff;
          position: absolute;
          left: 0;
          bottom: 0;
          display: inline-block;
        }

        .forBackgroundImage {
          background-size: 70% !important;
          background-position: center !important;
          background-repeat: no-repeat !important;
          width: 100%;
          // z-index: -1;
          margin: 0;
          opacity: 1;
          height: 100%;
        }

        .col-md-3 {
          width: 100%;
          margin: 0;
          border: none;
          height: none;

          .thumb-container {
            width: 100%;
            // height: 317px;
            position: relative;
            padding-bottom: 100%;
            margin: 0;
            border: none;

            .thumb {
              position: absolute;
              width: 100%;
              height: 100%;
              text-align: center;
              margin: 0;
              border-top: none;
              border-bottom: none;
              border-left: none;

              a {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                max-width: 100%;
                max-height: 100%;

                img {
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  margin: auto;
                  max-width: 100%;
                  max-height: 100%;
                }
              }
            }
          }
        }

        .love:hover {
          background: url('../../../assets/images/17.png') no-repeat;
          background-size: 40px;
        }

        .active {
          background: url('../../../assets/images/17.png') no-repeat !important;
          background-size: 40px !important;
          position: absolute;
          width: 40px;
          height: 40px;
          top: 10px;
          right: 10px;
          margin: 0;
          background-size: 40px;
          border: transparent;
          border-radius: 50%;
        }

        .love {
          position: absolute;
          width: 40px;
          height: 40px;
          top: 10px;
          right: 10px;
          margin: 0;
          background: url('../../../assets/images/9.png') no-repeat;
          background-size: 40px;
          border: transparent;
          border-radius: 50%;
          z-index: 100;
        }

        // img {
        //   width: 100%;
        //   height: 100%;
        // }
      }

      .recommended-item-img-bottom {
        width: 100%;
        // height: 263px;
        margin: 0;
        box-sizing: border-box;
        padding: 0 20px;
        text-align: left;
        padding-top: 20px;

        p {
          margin-top: 13px;
          font-size: 16px;
          font-weight: 400;
          color: #333;
          margin-bottom: 0;
        }

        .font-size-small {
          font-size: 16px;
        }

        .start-pirce {
          margin-top: 10px;
          margin-bottom: 30px;
          font-weight: 600;
        }

        a:nth-child(1) {
          // height: 80px;
          width: 290px;
          overflow: hidden;
          display: -webkit-box;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          /*2行末尾显示点点点*/

          font-weight: 600;
          font-size: 16px;
          color: #333;

          // display: block;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .emty-brow {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
      padding-top: 69px;

      img {
        width: 169px;
      }

      span {
        padding-top: 69px;
        color: #cda156;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
</style>
